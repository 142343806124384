
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
import noImage from "../../images/noImage.png"
import { useFetchGst, useUpdateGst } from "../../hooks/GST";
import Modal from "react-bootstrap/esm/Modal";
import { Tooltip } from "@mui/material";


interface Data {
  selection: any
  product_name_and_grade: any
  product_color: any
  panni_code_and_grade_name: any
  panni_printing_color_name: any
  panni_printing_total_color: any
  product_brand: any
  our_product_brand: any
  product_ml_price_list: any
  product_ml_actual: any
  stick_packing_type: any
  stick_nos: any
  stick_qty: any
  carton_total_nos: any
  carton_code_and_grade_and_name: any
  carton_in_stick_packing: any
  product_single_piece_code: any
  product_price_list_1_piece_weight: any
  product_production_list_1_piece_weight: any
  mouldshape: any
  product_list_mould_diameter: any
  product_type: any
  product_final_stick_height_mm: any
  product_current_stick_height_mm: any

  created_at: any
  created_by: any
  updated_by: any
  updated_at: any
  //   status: any;
  actions: any;
}


export const titleofheaders_Disposable = [
  {
    id: `srno`,
    Header: `Sr No`,
  },

  {
    id: `product_type`,
    Header: `Product Type`,

  },

  {
    id: `product_code`,
    Header: `Product Code`,

  },

  {
    id: `product_name_and_grade`,
    Header: `Product Name & Grade`,

  },
  {
    id: `product_color`,
    Header: `Product Color`,

  },
  {
    id: `panni_code_and_grade_name`,
    Header: `Product Code Name & Grade`,


  },
  {
    id: `panni_printing_color_name`,
    Header: `Panni Printing Color Name`,


  },
  {
    id: `panni_printing_total_color`,
    Header: `Panni Printing Total Color`,

  },
  {
    id: `product_brand`,
    Header: `Product Brand`,


  },
  {
    id: `our_product_brand`,
    Header: `Product Brand Type`,


  },

  {
    id: `stick_packing_type`,
    Header: `Stick Packing Type`,


  },

  {
    id: `carton_code_and_grade_and_name`,
    Header: `Carton Code Name & Grade`,

  },

  {
    id: `product_single_piece_code`,
    Header: `Product single piece code`,

  },

  {
    id: 'created_at',
    Header: `Created At`,



  },

  {
    id: 'created_by',
    Header: `Created By`,



  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },

]


export const Columns_DisposableProduct: any = [




  {
    id: `srno`,
    Header: () => <div style={{ display: "inline" }}>Sr<br />No</div>,
    sticky: "left",
  },

  {
    id: `product_code`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Code</div>,
    accessor: 'product_code',
    sticky: "left",

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_code}
        </div>
      )
    }

  },

  {
    id: `product_grade_name`,
    Header: () => <div style={{ display: "inline" }}>Product Grade &<br />Name & Type</div>,
    accessor: 'product_grade_name',


    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.material_type_name} {row.original.product_single_piece_code?.extra_response?.cavity_to_product?.product_name} {row.original.product_single_piece_code?.extra_response?.HSN_code?.product_categories_name}
        </div>
      )
    }

  },
  {
    id: `product_single_piece_code`,
    Header: () => <div style={{ display: "inline" }}>Product Single<br />Piece Code</div>,
    accessor: 'product_single_piece_code',


    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.single_piece_code}
        </div>
      )
    }

  },
  {
    id: `product_type`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Category</div>,
    accessor: 'product_type',


    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_type?.name ? row.original.product_type?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },



  {
    id: `material_name_type`,
    Header: () => <div style={{ display: "inline" }}>Material Name & Type<br />& Category & HSN & GST%</div>,
    accessor: 'material_name_type',
    Cell: ({ row }: any) => {
      const Material: any = `${row.original.product_single_piece_code?.extra_response?.HSN_code?.material_type_name}::${row.original.product_single_piece_code?.extra_response?.HSN_code?.particular_sector_name}
      ::${row.original.product_single_piece_code?.extra_response?.HSN_code?.product_categories_name}::${row.original.product_single_piece_code?.extra_response?.HSN_code?.hsn_code}
      ::${row.original.product_single_piece_code?.extra_response?.HSN_code?.india_GST}`
      // return (
      //     <div>
      //         {row.original.hsn_code?.grade_type?.material_type_name}::{row.original.hsn_code?.particular_sector_name?.name ? row.original.hsn_code?.particular_sector_name?.name : <span style={{ color: 'red' }}>****</span>}::
      //         {row.original.hsn_code?.product_categories?.name ? row.original.hsn_code?.product_categories?.name : <span style={{ color: 'red' }}>****</span>}::
      //         {row.original.hsn_code?.hsn_code}::{row.original?.hsn_code?.gst?.india_GST}
      //     </div>
      // )
      return (
        <>
          <Tooltip title={<p style={{ fontSize: '15px', color: 'white' }}>{Material}</p>} placement="top" arrow >
            <div>
              {Material.length >= 2 ? Material?.slice(0, 19)?.concat('...') : Material}
            </div>
          </Tooltip>

        </>
      )
    }


  },
  {
    id: `gst_type`,
    Header: () => <div style={{ display: "inline" }}>GST<br />Type</div>,
    accessor: 'gst_type',


    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.gst_type_name}
        </div>
      )
    }

  },
  {
    id: `type_name_density`,
    Header: () => <div style={{ display: "inline" }}>Type Name & Density<br />& Sutra & Gst</div>,
    accessor: 'type_name_density',


    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.material_type_name}::{row.original.product_single_piece_code?.extra_response?.HSN_code?.material_density}::
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.grade_sutra}::{row.original.product_single_piece_code?.extra_response?.HSN_code?.grade_type_india_GST}
        </div>
      )
    }

  },
  {
    id: `use_singlecode_gradename`,
    Header: () => <div style={{ display: "inline" }}>Use Product Single Pice<br />Code & Grade & Name & Type</div>,
    accessor: 'use_singlecode_gradename',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.use_singlecode_gradename ? row.original.use_singlecode_gradename : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }


  },
  {
    id: `use_product_code_qty`,
    Header: () => <div style={{ display: "inline" }}>Use Product <br />Code Qty</div>,
    accessor: 'use_product_code_qty',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.use_product_code_qty ? row.original.use_product_code_qty : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }


  },
  {
    id: `material_type`,
    Header: () => <div style={{ display: "inline" }}>Material <br />Type</div>,
    accessor: 'material_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.material_type?.name ? row.original.product_single_piece_code?.material_type?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }


  },
  {
    id: `product_color`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Color</div>,
    accessor: 'product_color',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.company_color_name ? row.original.product_single_piece_code?.extra_response?.company_color_name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }


  },
  {
    id: `product_brand`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Brand</div>,
    accessor: 'product_brand',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.product_brand_name ? row.original.extra_response?.product_brand_name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `our_product_brand`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Brand Type</div>,
    accessor: 'our_product_brand',
    Cell: ({ row }: any) => {
      const convertToUpper = row.original.our_product_brand
      if (!convertToUpper) return <span style={{ color: 'red', fontSize: "20px" }}>****</span>;
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }
    // Cell: ({ row }: any) =>{
    //     return(
    //     <div>
    //         {row.original.our_product_brand}
    //     </div>
    // )}

  },
  {
    id: `panni_code_and_grade_name`,
    Header: () => <div style={{ display: "inline" }}>Packing Material Code<br />& Grade & Name</div>,
    accessor: 'panni_code_and_grade_name',



    Cell: ({ row }: any) => {
      return (
        <div>
          {`${row.original.extra_response?.panni_code_and_grade_name?.panni_code} ${row.original.extra_response?.panni_code_and_grade_name?.material_type_name} ${row.original.extra_response?.panni_code_and_grade_name?.particular_sector_name}`}
        </div>
      )
    }

  },
  {
    id: `carton_code`,
    Header: () => <div style={{ display: "inline" }}>Carton Code<br />& Grade & Name</div>,
    accessor: 'carton_code',



    Cell: ({ row }: any) => {
      return <div>
        {row.original?.extra_response?.carton ?
          <>
            {`${row.original.extra_response?.carton?.carton_code} ${row.original.extra_response?.carton?.material_type_name} ${row.original.extra_response?.carton?.particular_sector_name}`}
          </>
          : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
      </div>

    }


  },
  {
    id: `product_pricelist_one_pice_weight`,
    Header: () => <div style={{ display: "inline" }}>Product Price-List<br />1 Piece Weight</div>,
    accessor: 'product_pricelist_one_pice_weight',



    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.price_list_1_pcs_weight === null ? <span style={{ color: 'red', fontSize: "20px" }}>****</span> : row.original.product_single_piece_code?.price_list_1_pcs_weight}
        </div>
      )
    }

  },
  {
    id: `product_pricelist_one_kg_in_pice`,
    Header: () => <div style={{ display: "inline" }}>Product Price-List<br />1 Kg in Piece</div>,
    accessor: 'product_pricelist_one_kg_in_pice',



    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_pricelist_one_kg_in_pice ? row.original.product_pricelist_one_kg_in_pice : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_production_one_pice_weight`,
    Header: () => <div style={{ display: "inline" }}>Product Production<br />1 Piece Weight</div>,
    accessor: 'product_production_one_pice_weight',



    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_production_one_pice_weight ? row.original.product_production_one_pice_weight: <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_production_one_kg_in_pice`,
    Header: () => <div style={{ display: "inline" }}>Product Production<br />1 Kg in Piece</div>,
    accessor: 'product_production_one_kg_in_pice',



    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_production_one_kg_in_pice ? row.original.product_production_one_kg_in_pice : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_automatic_one_pice_weight`,
    Header: () => <div style={{ display: "inline" }}>Product Production<br />1 Piece Weight</div>,
    accessor: 'product_automatic_one_pice_weight',



    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_automatic_one_pice_weight ? row.original.product_automatic_one_pice_weight : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_automatic_one_kg_in_pice`,
    Header: () => <div style={{ display: "inline" }}>Product Automatic<br />1 Kg in Piece</div>,
    accessor: 'product_automatic_one_kg_in_pice',



    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_automatic_one_kg_in_pice ? row.original.product_automatic_one_kg_in_pice : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `mould_shape`,
    Header: () => <div style={{ display: "inline" }}>Mould<br />Shape</div>,
    accessor: 'mould_shape',



    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.mould_shape?.mould_shape_name ? row.original.product_single_piece_code?.extra_response?.mould_shape?.mould_shape_name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `shape_image`,
    Header: () => <div style={{ display: "inline" }}>Mould Shape<br />Image</div>,
    accessor: 'shape_image',
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)


      return (
        <>


          <div >

            {!`${process.env.REACT_APP_BASE_URL}media/${row.original.product_single_piece_code.extra_response?.mould_shape?.shape_image}` ? <>
              {/* <a 
          onClick={()=>setDeleteConfirm(true)}
          href={`#galleryModal${row.original.name}`}
          
          data-large-src={`${process.env.REACT_APP_BASE_URL}/media/${row.original.extra_response?.mould_shape?.shape_image}`} data-toggle="modal">
            <img src={`${process.env.REACT_APP_BASE_URL}/media/${row.original.extra_response?.mould_shape?.shape_image}`}  height={30} width={'auto'}/>
            </a> */}
            </> : <>
              {/* <a 
          onClick={()=>setDeleteConfirm(true)}
          href={`#galleryModal${row.original.name}`}
          
          data-large-src={noImage} data-toggle="modal">
            <img src={noImage}  height={30} width={'auto'}/>
            </a> */}


              <a
                onClick={() => setDeleteConfirm(true)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={row.original.product_single_piece_code.extra_response?.mould_shape?.shape_image ? `${process.env.REACT_APP_BASE_URL}media/${row.original.product_single_piece_code.extra_response?.mould_shape?.shape_image}` : noImage} data-toggle="modal">
                <img src={row.original.product_single_piece_code.extra_response?.mould_shape?.shape_image ? `${process.env.REACT_APP_BASE_URL}media/${row.original.product_single_piece_code.extra_response?.mould_shape?.shape_image}` : noImage} height={30} width={'auto'} />
              </a>
              {/* <a 
          onClick={()=>setDeleteConfirm(true)}
          href={`#galleryModal${row.original.name}`}
          
          data-large-src={`${process.env.REACT_APP_BASE_URL}media/${row.original.extra_response?.mould_shape?.shape_image}`?`${process.env.REACT_APP_BASE_URL}/media/${row.original.extra_response?.mould_shape?.shape_image}`:''} data-toggle="modal">
            <img src={`${process.env.REACT_APP_BASE_URL}media/${row.original.extra_response?.mould_shape?.shape_image}`?`${process.env.REACT_APP_BASE_URL}/media/${row.original.extra_response?.mould_shape?.shape_image}`:''}  height={30} width={'auto'}/>
            </a> */}
            </>}

          </div>

          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >
            <Modal.Header >
              <Modal.Title>

              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <img src={`${process.env.REACT_APP_BASE_URL}/media/${row.original.extra_response?.mould_shape?.shape_image}`?`${process.env.REACT_APP_BASE_URL}/media/${row.original.extra_response?.mould_shape?.shape_image}`:noImage} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid"/> */}
              <img src={row.original.product_single_piece_code.extra_response?.mould_shape?.shape_image ? `${process.env.REACT_APP_BASE_URL}media/${row.original.product_single_piece_code.extra_response?.mould_shape?.shape_image}` : noImage} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid" />

            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>

        </>
      )
    }
  },


  {
    id: `product_mould_diameter_mm`,
    Header: () => <div style={{ display: "inline" }}>Production Mould<br />Diameter mm</div>,

    // Header: `Product Actual ML`,
    accessor: `product_mould_diameter_mm`,
    Cell: ({ row }: any) => row.original.product_single_piece_code.extra_response?.mould_shape?.product_mould_diameter_mm ? row.original.product_single_piece_code.extra_response?.mould_shape?.product_mould_diameter_mm : <span style={{ color: 'red', fontSize: "20px" }}>****</span>
  },
  {
    id: `product_width_diameter`,
    Header: () => <div style={{ display: "inline" }}>Production Mould<br />Width mm</div>,

    // Header: `Product Actual ML`,
    accessor: `product_width_diameter`,
    Cell: ({ row }: any) => row.original.product_single_piece_code.extra_response?.mould_shape?.product_width_diameter ? row.original.product_single_piece_code.extra_response?.mould_shape?.product_width_diameter : <span style={{ color: 'red', fontSize: "20px" }}>****</span>
  },
  {
    id: `product_length_diameter`,
    Header: () => <div style={{ display: "inline" }}>Production Mould<br />Length mm</div>,

    // Header: `Product Actual ML`,
    accessor: `product_length_diameter`,
    Cell: ({ row }: any) => row.original.product_single_piece_code.extra_response?.mould_shape?.product_length_diameter ? row.original.product_single_piece_code.extra_response?.mould_shape?.product_length_diameter : <span style={{ color: 'red', fontSize: "20px" }}>****</span>
  },
  {
    id: `cavity_qty`,
    Header: () => <div style={{ display: "inline" }}>Mould Cavity<br />Qty</div>,

    // Header: `Product Actual ML`,
    accessor: `cavity_qty`,
    Cell: ({ row }: any) => row.original.product_single_piece_code.extra_response?.mould_shape?.cavity_qty ? row.original.product_single_piece_code.extra_response?.mould_shape?.cavity_qty : <span style={{ color: 'red', fontSize: "20px" }}>****</span>
  },
  {
    id: `product_ml_price_list`,
    Header: () => <div style={{ display: "inline" }}>Product ML<br />Price List</div>,

    // Header: `Product ML Price List`,
    accessor: `product_ml_price_list`,
    Cell: ({ row }: any) => row.original?.product_single_piece_code.product_ml_price_list?.name ? row.original?.product_single_piece_code.product_ml_price_list?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>
  },



  {
    id: `product_actual_ml`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Actual ML</div>,

    // Header: `Product Actual ML`,
    accessor: `product_actual_ml`,
    Cell: ({ row }: any) => row.original.product_single_piece_code.product_actual_ml?.name ? row.original.product_single_piece_code.product_actual_ml?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>
  },
  //   {
  //     id: `panni_printing_color_name`,
  //     Header: () => <div style={{display:"inline"}}>Panni Printing<br/>Color Name</div>,
  //     accessor:'panni_printing_color_name',
  //     Cell: ({ row }: any) => {
  //       let printingColor = row.original?.extra_response?.panni_code_and_grade_name?.printing_color_name?.map((e:any)=>{
  //         return e
  //       })
  //       if (printingColor.length === 0) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
  //       return printingColor?.join(",")

  // }


  //   },


  // {
  //   id: `product_ml_price_list`,
  //   Header: `Product Ml Price List`,
  //   accessor:'product_ml_price_list',

  //   Cell: ({ row }: any) =>{
  //       return(
  //       <div>
  //           {row.original.product_ml_price_list}
  //       </div>
  //   )}

  // },
  // {
  //   id: `product_ml_actual`,
  //   Header: `Product Ml Actual`,
  //   accessor:'product_ml_actual',

  //   Cell: ({ row }: any) =>{
  //       return(
  //       <div>
  //           {row.original.product_ml_actual}
  //       </div>
  //   )}

  // },
  {
    id: `stick_packing_type`,
    Header: () => <div style={{ display: "inline" }}>Stick Packing<br />Type</div>,
    accessor: 'stick_packing_type',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.extra_response?.stick_packing_type ? row.original?.extra_response?.stick_packing_type : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },



  // {
  //   id: `product_single_piece_code`,
  //   Header: () => <div style={{display:"inline"}}>Product single<br/>piece code</div>,
  //   accessor:'product_single_piece_code',

  //   Cell: ({ row }: any) =>{
  //       return(
  //       <div>
  //           {row.original.extra_response?.single_piece_code}
  //       </div>
  //   )}

  // },
  {
    id: `stick_nos`,
    Header: () => <div style={{ display: "inline" }}>Packing Stick<br />Nos</div>,
    // Header: `Stick Nos`,
    accessor: 'stick_nos',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.stick_nos ? row.original.stick_nos : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `stick_qty`,
    Header: () => <div style={{ display: "inline" }}>Bundle Stick<br />Qty</div>,
    // Header: `Stick Qty`,
    accessor: 'stick_qty',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.stick_qty ? row.original.stick_qty : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },

  {
    id: `carton_in_stick_packing`,
    Header: () => <div style={{ display: "inline" }}>Carton In<br />Stick Qty</div>,
    // Header: `Carton In Stick Packing`,
    accessor: 'carton_in_stick_packing',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.carton_in_stick_packing ? row.original.carton_in_stick_packing : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `carton_total_nos`,
    Header: () => <div style={{ display: "inline" }}>Carton In<br /> Total Nos</div>,
    // Header: `Carton Total Nos`,
    accessor: 'carton_total_nos',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.carton_total_nos ? row.original.carton_total_nos : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_final_height_mm`,
    Header: () => <div style={{ display: "inline" }}>Product Final<br />Height MM</div>,
    // Header: `Product Final Stick Height MM`,
    accessor: 'product_final_height_mm',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.product_final_height_mm ? row.original.product_single_piece_code?.product_final_height_mm : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_current_height_mm`,
    Header: () => <div style={{ display: "inline" }}>Product Current<br />Height MM</div>,
    // Header: `Product Current Stick Height MM`,
    accessor: 'product_current_height_mm',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.product_current_height_mm ? row.original.product_single_piece_code?.product_current_height_mm : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_final_stick_height_mm`,
    Header: () => <div style={{ display: "inline" }}>Product Final<br />Stick Height MM</div>,
    // Header: `Product Final Stick Height MM`,
    accessor: 'product_final_stick_height_mm',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_final_stick_height_mm ? row.original.product_final_stick_height_mm : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_current_stick_height_mm`,
    Header: () => <div style={{ display: "inline" }}>Product Current<br />Stick Height MM</div>,
    // Header: `Product Current Stick Height MM`,
    accessor: 'product_current_stick_height_mm',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_current_stick_height_mm ? row.original.product_current_stick_height_mm : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_lip`,
    Header: () => <div style={{ display: "inline" }}>Product <br />Lip</div>,
    // Header: `Product Current Stick Height MM`,
    accessor: 'product_lip',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.current_lip?.name ? row.original.product_single_piece_code?.current_lip?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: 'product_printing',
    Header: () => <div style={{ display: "inline" }}>Product<br />Printing</div>,

    accessor: 'product_printing',
    Cell: ({ row }: any) => {
      const convertToUpper = row.original?.product_printing
      if (!convertToUpper) return <span style={{ color: 'red', fontSize: "20px" }}>****</span>;
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }

  },
  {
    id: 'panni_printing_type',
    Header: () => <div style={{ display: "inline" }}>Product Printing<br />Printing Type</div>,
    // Header: 'Printing Type',
    accessor: 'panni_printing_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.panni_printing_type?.name ? row.original?.panni_printing_type?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
      // if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      // return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }

  },
  {
    id: 'panni_printing_color_name',
    Header: () => <div style={{ display: "inline" }}>Product Printing<br />Color Name</div>,
    // Header: 'Printing Color Name',
    accessor: 'panni_printing_color_name',



    Cell: ({ row }: any) => {
      let printingColor = row.original?.extra_response?.company_color_name?.map((e: any) => {
        return e
      })
      if (printingColor.length === 0) return <span style={{ color: 'red', fontSize: "20px" }}>****</span>;
      return printingColor?.join(",")

    }


  },
  {
    id: 'panni_printing_total_color',
    Header: () => <div style={{ display: "inline" }}>Panni Printing<br />Total Color</div>,
    // Header: 'Printing Total Color',
    accessor: 'panni_printing_total_color',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.panni_printing_total_color ? row.original?.panni_printing_total_color : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: 'product_stock_minimum_qty',
    Header: () => <div style={{ display: "inline" }}>Cartoon Stock<br />Minimum Qty </div>,
    // Header: 'Printing Total Color',
    accessor: 'product_stock_minimum_qty',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.product_stock_minimum_qty ? row.original?.product_stock_minimum_qty : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: 'product_stock_maximum_qty',
    Header: () => <div style={{ display: "inline" }}>Cartoon Stock<br />Maximum Qty </div>,
    // Header: 'Printing Total Color',
    accessor: 'product_stock_maximum_qty',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.product_stock_maximum_qty ? row.original?.product_stock_maximum_qty : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_by`,
    Header: () => <div style={{ display: "inline" }}>Product<br />By</div>,

    accessor: 'product_by',
    Cell: ({ row }: any) => {
      //   const convertToUpper = row.original.product_by?.name
      //   // const convertToUpper = row.original.product_by
      //   if (!convertToUpper) return <span style={{ color: 'red', fontSize: "20px" }}>****</span>;
      //   // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      //   return convertToUpper
      //   // return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
      return (
        <div>
          {row.original.product_by?.name ? row.original.product_by?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }
  },
  {
    id: `product_special_running`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Type</div>,

    accessor: 'product_special_running',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_special_running?.name ? row.original.product_special_running?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }
  },
  {
    id: `product_debit_day`,
    Header: () => <div style={{ display: "inline" }}>Product Maximum<br />Debit Day</div>,
    // Header: `Product Debit Day   `,
    accessor: 'product_debit_day',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_debit_day ? row.original.product_debit_day : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_location`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Location</div>,

    accessor: 'product_location',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_location?.name ? row.original.product_location?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },

  {
    id: 'material_name',
    Header: () => <div style={{ display: "inline" }}>Material Grade<br />Name</div>,
    accessor: 'material_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.material_type_name}
        </div>
      )
    }

  },
  {
    id: 'product_name',
    Header: () => <div style={{ display: "inline" }}>Product<br />Name</div>,
    accessor: 'product_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.cavity_to_product?.product_name ? row.original.product_single_piece_code?.extra_response?.cavity_to_product?.product_name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: 'material_type_name',
    Header: () => <div style={{ display: "inline" }}>Material<br />Type</div>,
    accessor: 'material_type_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.particular_sector_name}
        </div>
      )
    }

  },
  {
    id: 'material_category',
    Header: () => <div style={{ display: "inline" }}>Material<br />Category</div>,
    accessor: 'material_category',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.product_categories_name}
        </div>
      )
    }

  },
  {
    id: 'material_hsn',
    Header: () => <div style={{ display: "inline" }}>HSN<br />Code</div>,
    accessor: 'material_hsn',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.hsn_code}
        </div>
      )
    }

  },

  {
    id: 'material_gst',
    Header: () => <div style={{ display: "inline" }}>Product<br />GST%</div>,
    accessor: 'material_gst',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.india_GST}
        </div>
      )
    }

  },
  {
    id: 'product_density',
    Header: () => <div style={{ display: "inline" }}>Product<br />Density</div>,
    accessor: 'product_density',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.material_density ? row.original.product_single_piece_code?.extra_response?.HSN_code?.material_density : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },

  {
    id: `day_packet_packing_labour_rate`,
    Header: () => <div style={{ display: "inline" }}>Day Packet<br />Packing Labour Rate</div>,
    // Header: `Day Packet Packing Labour Rate`,
    accessor: 'day_packet_packing_labour_rate',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.day_packet_packing_labour_rate ? row.original.day_packet_packing_labour_rate : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `night_packet_packing_labour_rate`,
    Header: () => <div style={{ display: "inline" }}>Night Packet<br />Packing Labour Rate</div>,
    // Header: `Day Packet Packing Labour Rate`,
    accessor: 'night_packet_packing_labour_rate',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.day_carton_packing_labour_rate ? row.original.day_carton_packing_labour_rate : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `day_carton_packing_labour_rate`,
    Header: () => <div style={{ display: "inline" }}>Day Cartoon<br />Packing Labour Rate</div>,
    // Header: `Day Cartoon Packing Labour Rate`,
    accessor: 'day_carton_packing_labour_rate',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.day_carton_packing_labour_rate ? row.original.day_carton_packing_labour_rate : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `night_carton_packing_labour_rate`,
    Header: () => <div style={{ display: "inline" }}>Night Cartoon<br />Packing Labour Rate</div>,
    // Header: `Day Cartoon Packing Labour Rate`,
    accessor: 'night_carton_packing_labour_rate',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.night_carton_packing_labour_rate ? row.original.night_carton_packing_labour_rate : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `carton_code_and_grade_and_name`,
    Header: () => <div style={{ display: "inline" }}>Patti Code<br />& Grade & Name</div>,
    // Header: `Day Cartoon Packing Labour Rate`,
    accessor: 'carton_code_and_grade_and_name',

    Cell: ({ row }: any) => {

      return <div>
        {row.original?.extra_response?.box_patti ?
          <>
            {`${row.original.extra_response?.box_patti?.box_patti_code} ${row.original.extra_response?.box_patti?.material_type_name} ${row.original.extra_response?.box_patti?.particular_sector_name}`}
          </>
          : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
      </div>

    }

  },
  {
    id: `tape_roll_code_and_grade_name`,
    Header: () => <div style={{ display: "inline" }}>Tape Roll Code<br />& Grade & Name</div>,
    // Header: `Day Cartoon Packing Labour Rate`,
    accessor: 'tape_roll_code_and_grade_name',

    Cell: ({ row }: any) => {
      return <div>
        {row.original?.extra_response?.tape_roll ?
          <>
            {`${row.original.extra_response?.tape_roll?.tape_roll_code} ${row.original.extra_response?.tape_roll?.material_type_name} ${row.original.extra_response?.tape_roll?.particular_sector_name}`}
          </>
          : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
      </div>

    }

  },
  {
    id: `use_product_code_and_grade_name`,
    Header: () => <div style={{ display: "inline" }}>Use Product Code<br />& Grade & Name & Type</div>,
    // Header: `Day Cartoon Packing Labour Rate`,
    accessor: 'use_product_code_and_grade_name',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.use_product_code_and_grade_name ? row.original.use_product_code_and_grade_name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `use_product_qty`,
    Header: () => <div style={{ display: "inline" }}>Use Product<br />Qty</div>,
    // Header: `Day Cartoon Packing Labour Rate`,
    accessor: 'use_product_qty',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.use_product_qty ? row.original.use_product_qty : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `use_cavity_code_and_name`,
    Header: () => <div style={{ display: "inline" }}>Use Cavity Code<br />& Name</div>,
    // Header: `Day Cartoon Packing Labour Rate`,
    accessor: 'use_cavity_code_and_name',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.use_cavity_code_and_name ? row.original.use_cavity_code_and_name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `use_cavity_qty`,
    Header: () => <div style={{ display: "inline" }}>Use Cavity<br />Qty</div>,
    // Header: `Day Cartoon Packing Labour Rate`,
    accessor: 'use_cavity_qty',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.use_cavity_qty ? row.original.use_cavity_qty : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `use_mould_code_and_cavity_qty`,
    Header: () => <div style={{ display: "inline" }}>Use Mould Code<br />& Cavity Qty</div>,
    // Header: `Day Cartoon Packing Labour Rate`,
    accessor: 'use_mould_code_and_cavity_qty',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.use_mould_code_and_cavity_qty ? row.original.use_mould_code_and_cavity_qty : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },

  // {
  //   id: `product_opening_stock_qty`,
  //   Header: () => <div style={{ display: "inline" }}>Product Opening<br />Stock Qty</div>,
  //   // Header: `Product Opening Stock Qty  `,
  //   accessor: 'product_opening_stock_qty',

  //   Cell: ({ row }: any) => {
  //     return (
  //       <div>
  //         {row.original.product_opening_stock_qty}
  //       </div>
  //     )
  //   }

  // },
  // {
  //   id: `product_our_other`,
  //   Header: () => <div style={{ display: "inline" }}>Product<br />Our-Other</div>,
  //   // Header: `Product Our-Other  `,
  //   accessor: 'product_our_other',
  //   Cell: ({ row }: any) => {
  //     const convertToUpper = row.original.product_our_other
  //     if (!convertToUpper) return "";
  //     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
  //     return convertToUpper.split("_").map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
  //   }
  //   // Cell: ({ row }: any) =>{
  //   //     return(
  //   //     <div>
  //   //         {row.original.product_our_other}
  //   //     </div>
  //   // )}

  // },

  // {
  //   id: `product_special_running`,
  //   Header: () => <div style={{display:"inline"}}>Product<br/>Special-Running</div>,
  //   // Header: `Product Debit Day   `,
  //   accessor:'product_special_running',
  //   Cell: ({row}:any) => {
  //     const convertToUpper = row.original.product_special_running
  //     if (!convertToUpper) return "";
  //     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
  //     return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
  //   }
  //   // Cell: ({ row }: any) =>{
  //   //     return(
  //   //     <div>
  //   //         {row.original.product_special_running}
  //   //     </div>
  //   // )}

  // },

  // {
  //   id: `product_location`,
  //   Header: () => <div style={{display:"inline"}}>Product<br/>Location</div>,
  //   // Header: `Product Debit Day   `,
  //   accessor:'product_location',

  //   Cell: ({ row }: any) =>{
  //       return(
  //       <div>
  //           {row.original.product_location?row.original.product_location:row.original.product_location?.name}
  //       </div>
  //   )}

  // },

  {
    id: 'remark',
    Header: () => <div style={{ display: "inline" }}>Remark</div>,
    accessor: 'remark',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.remark ? row.original.remark : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',


  },


  {
    id: 'created_at',
    Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
    accessor: 'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split("T")[0] : "-")


  },

  {
    id: 'created_by',
    Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
    accessor: 'created_by',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_by?.username : "-")


  },
  {
    id: 'updated_at',
    Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
    accessor: 'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split("T")[0] : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
    accessor: 'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },

  {
    id: `bori_and_carton_image`,
    Header: () => <div style={{ display: "inline" }}>Bori & Carton Packing<br />Image All</div>,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)
      const [currentIndex, setCurrentIndex] = useState(0);
      const [fieldImages, setFieldImages] = useState([])

      const handleShowImages = (field: any) => {
        
        setDeleteConfirm(true)
        setFieldImages(field.bori_and_carton_image)
      }
      const goToPrev = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };

      const goToNext = () => {
        const isLastSlide = currentIndex === fieldImages?.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };
      return (
        <>
          <div >

            {!row.original.bori_and_carton_image || row.original.bori_and_carton_image?.length === 0 ? <>
              <a

                href="#"

              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => handleShowImages(row.original)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.bori_and_carton_image.length > 0 ? row.original.bori_and_carton_image.at(-1).doc : noImage)} data-toggle="modal">
                <img src={row.original.bori_and_carton_image[0]?.doc ? row.original.bori_and_carton_image[0]?.doc : noImage} alt='no image' width={50} height={50} />
              </a>
            </>}

          </div>

          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">

                </div>



                <div className="carousel-inner">
                  {row.original.bori_and_carton_image?.map((imgSrc: any, index: any) => (
                    <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                      <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                    </div>
                  ))}
                </div>

                <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> 
                  <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" onClick={goToNext} >
                  {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier"> <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'space-between' }}>
              <p>{currentIndex + 1}/{fieldImages.length}</p>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }
  },



  {
    id: `product_packet_packing_image`,
    Header: () => <div style={{ display: "inline" }}>Product Packet<br />Packing Image All</div>,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)
      const [currentIndex, setCurrentIndex] = useState(0);
      const [fieldImages, setFieldImages] = useState([])

      const handleShowImages = (field: any) => {
        
        setDeleteConfirm(true)
        setFieldImages(field.product_packet_packing_image)
      }
      const goToPrev = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };

      const goToNext = () => {
        const isLastSlide = currentIndex === fieldImages?.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };
      return (
        <>
          <div >

            {!row.original.product_packet_packing_image || row.original.product_packet_packing_image?.length === 0 ? <>
              <a

                href="#"

              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => handleShowImages(row.original)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.product_packet_packing_image.length > 0 ? row.original.product_packet_packing_image.at(-1).doc : noImage)} data-toggle="modal">
                <img src={row.original.product_packet_packing_image[0]?.doc ? row.original.product_packet_packing_image[0]?.doc : noImage} alt='no image' width={50} height={50} />
              </a>
            </>}

          </div>

          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">

                </div>



                <div className="carousel-inner">
                  {row.original.product_packet_packing_image?.map((imgSrc: any, index: any) => (
                    <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                      <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                    </div>
                  ))}
                </div>

                <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> 
                  <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" onClick={goToNext} >
                  {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier"> <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'space-between' }}>
              <p>{currentIndex + 1}/{fieldImages.length}</p>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }
  },




  {
    id: `product_image`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Image All</div>,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)
      const [currentIndex, setCurrentIndex] = useState(0);
      const [fieldImages, setFieldImages] = useState([])

      const handleShowImages = (field: any) => {
        
        setDeleteConfirm(true)
        setFieldImages(field.product_image)
      }
      const goToPrev = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };

      const goToNext = () => {
        const isLastSlide = currentIndex === fieldImages?.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };
      return (
        <>
          <div >

            {!row.original.product_image || row.original.product_image?.length === 0 ? <>
              <a

                href="#"

              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => handleShowImages(row.original)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.product_image.length > 0 ? row.original.product_image.at(-1).doc : noImage)} data-toggle="modal">
                <img src={row.original.product_image[0]?.doc ? row.original.product_image[0]?.doc : noImage} alt='no image' width={50} height={50} />
              </a>
            </>}

          </div>

          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">

                </div>



                <div className="carousel-inner">
                  {row.original.product_image?.map((imgSrc: any, index: any) => (
                    <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                      <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                    </div>
                  ))}
                </div>

                <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> 
                  <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" onClick={goToNext} >
                  {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier"> <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'space-between' }}>
              <p>{currentIndex + 1}/{fieldImages.length}</p>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }
  },








  {
    id: `product_pdf_drawing`,
    Header: () => <div style={{ display: "inline" }}>Product<br />PDF Drawing All</div>,

    // accessor: `pdf`,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)

      return (
        <>
          <div>
            {!row.original.product_pdf_drawing || row.original.product_pdf_drawing?.length === 0 ? <>
              <a

                href="#"
                className="text-danger"
              >
                No Document Uploaded
              </a>
            </> : <>
              <a
                onClick={() => setDeleteConfirm(row.original)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.product_pdf_drawing.length > 0 ? row.original.product_pdf_drawing.at(-1).doc : null)} data-toggle="modal">
                <p>{(row.original.product_pdf_drawing?.length > 0 ? row.original.product_pdf_drawing?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}</p>

              </a>
            </>}

          </div>
          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              {row.original.product_pdf_drawing?.map((document: any) => (
                <li style={{ color: 'black' }}>
                  <a style={{ color: 'black', textDecoration: 'none' }} target="_blank" href={document?.doc}>
                    {document?.doc?.split('/').pop()}
                  </a>
                </li>))}
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>
      )
    }

  },









  {
    id: 'Actions',
    Header: 'Actions',


  },


  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_DisposableProduct, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows } = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}disposableproduct/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



