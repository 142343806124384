import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";


const FilterInput = ({
  filter,
  column,
  FilterBy,
  setFilterBy,
  setPage,
}: any) => {

  const [deleteOn, setDeleteON] = useState(false)
  const setHandleFilter = async (e: any) => {

    setPage(1);
    for (const [key, value] of Object.entries(FilterBy)) {
      if (e.target.name === key && e.target.value.trim()) {
        FilterBy[key] = e.target.value;
        setDeleteON(true)
        await setFilterBy({ ...FilterBy });
      } else if (!e.target.value.trim() && e.target.name === key) {
        FilterBy[key] = "";
        setDeleteON(false)
        await setFilterBy({ ...FilterBy });
      }
    }
  };



  return (
    <>
      {filter && column.id in FilterBy && column.id === "status" ? (
        <>
          {/* <FilterStatus/> */}

          <div
            style={{ top: "50%", position: "sticky" }}
            className="o-switch btn-group"
            data-toggle="buttons"
            role="group"
          >
            <label
              className="btn btn-secondary "
            // style={{background:"gray"}}
            >
              <input
                type="radio"
                name="status"
                id="option1"
                hidden
                autoComplete="off"
                value={"inactive"}
                onClick={setHandleFilter}
              />
              <i className="fa fa-times"></i>
            </label>

            <label
              className="btn btn-secondary active"
            // style={{background:"gray"}}
            >
              <input
                type="radio"
                name="status"
                id="option2"
                hidden
                autoComplete="off"
                value={""}
                onClick={async () => {
                  FilterBy[column.id] = "";
                  await setFilterBy({ ...FilterBy });
                }}
                checked
              />
              <i className="fa fa-minus"></i>
            </label>

            <label
              className="btn btn-secondary"
            // style={{background:"gray"}}
            >
              <input
                type="radio"
                name="status"
                id="option3"
                hidden
                autoComplete="off"
                value={"active"}
                onClick={setHandleFilter}
              />
              <i className="fa fa-check"></i>
            </label>
          </div>
        </>
      ) : (
        ""
      )}

      {filter && column.id in FilterBy && column.id != "status" ? (
        <div
          className="input-group m-auto w-100"
          style={{ top: "50%", position: "sticky" }}
          data-colorpicker-id={2}
        >
          <input
            type="text"
            className="form-control border-end-0 searchinput"
            // placeholder={column.Header}
            name={column.id}
            value={FilterBy[column.id]}
            onChange={setHandleFilter}
          />
          {deleteOn &&
            <div className="input-group-append">
              <span
                className="input-group-text bg-white border-start-0 searchinput"
                onClick={() => {
                  FilterBy[column.id] = "";
                  setDeleteON(false)
                  setFilterBy({ ...FilterBy });

                }}
              >
                <FontAwesomeIcon color="darkblue" icon={faXmark} />

              </span>
            </div>
          }
        </div>
      ) : (
        ""
      )}

      {/* 
      {filter && column.Header == "Color Code" ?
        <div className="input-group mt-4 m-auto w-75" data-colorpicker-id={2}>
          <input type="text" className="form-control border-end-0 searchinput" placeholder="Color Code"
            name='hex'
            value={FilterBy.hex}
            onChange={setHandleFilter}
          />
          <div className="input-group-append">
            <span className="input-group-text bg-white border-start-0 searchinput"
              onClick={() => setFilterBy({ hex: "", name: FilterBy.name })}
            ><FontAwesomeIcon color='darkblue' icon={faXmark} /></span>
          </div>
        </div>

        : ""}

      {filter && column.Header == "Color Name" ?
        <div className="input-group mt-4 m-auto w-75" data-colorpicker-id={2}>
          <input type="text" className="form-control border-end-0 searchinput" placeholder="Color Name"
            name='name' value={FilterBy.name} onChange={setHandleFilter}
          />
          <div className="input-group-append">
            <span className="input-group-text bg-white border-start-0 searchinput"
              onClick={() => setFilterBy({ hex: FilterBy.hex, name: "" })}
            ><FontAwesomeIcon color='darkblue' icon={faXmark} /></span>
          </div>
        </div>

        : ""} */}
    </>
  );
};

export default FilterInput;
