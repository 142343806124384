import React, { useEffect } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import "../../components/Table/table.css"
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Select from 'react-select'
import axios from 'axios'
import { isConstructorDeclaration } from "typescript";
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../hooks/Api";
import { excel } from "react-export-table-to-excel/lib/lib";
import { RiPencilLine } from "react-icons/ri";
import { useFetchBrandDropDown, useFetchColorDropDown, useFetchDisposableDropDown, useFetchGSTDropDown, useFetchMaterialDensityDropDown, useFetchMaterialTypeDropDown, useFetchUOMDropDown } from "../../hooks/DropDown_Api";
import { Alert } from "../../components/Model/Model";
import { DatePicker } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from 'dayjs';
// import Price_List_Table from "./table";
import Modal from "react-bootstrap/esm/Modal";
import { Deletesinglewithdependancy } from "../../components/modal";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
import Price_List_Table from "./table";
const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}


const Edit_PriceList = () => {
    let pageName = "Price List"
    const navigate = useNavigate()
    const { id } = useParams()
    const apiname = process.env.REACT_APP_PRICELIST_API?.toString()

    const [dataOn, setDataOn]: any = useState([])

    const { data } = useFetchSingle(id, apiname)

    useEffect(() => {
        if (data) {
            setDataOn(data)
        }
    }, [data])

    const { mutate, isSuccess, isError, error, data: Updated_response }: any = useUpdateSingle()

    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });


    const [mainData, setMainData] = useState([])

    // useEffect(()=>{
    //     if(data){
    //         setMainData(data)
    //     }
    // },[data])



    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()




    const [rateValue, setRateValue]: any = useState(0)

    const [oldValueCartonBasicRate, setOldValueCartonBasicRate] = useState(10)
    const [oldValueCartonRate, setOldValueCartonRate] = useState(20)
    const [oldValueKGRate, setOldValueKGRate] = useState(10)
    const [oldValueStickRate, setOldValueStickRate] = useState(20)





    const [OperationCartonBasicRate, setOperationCartonBasicRate] = useState('?');
    const [OperationCartonRate, setOperationCartonRate] = useState('?');
    const [OperationStickRate, setOperationStickRate] = useState('?');
    const [OperationKGRate, setOperationKGRate] = useState('?');
    // const [OperationModuloRate, setOperationModuloRate] = useState('?');

    const handleOperationCartonBasicRate = (event: any) => {
        setOperationCartonBasicRate(event.target.value);
    };


    const handleOperationCartonRate = (event: any) => {
        setOperationCartonRate(event.target.value);
    };

    const handleOperationStickRate = (event: any) => {
        setOperationStickRate(event.target.value);
    };

    const handleOperationKGRate = (event: any) => {
        setOperationKGRate(event.target.value);
    };

    // const handleOperationModuloRate = (event:any) => {
    //     setOperationModuloRate(event.target.value);
    // };


    const [changeValueCartonBasicRate, setChangeValueCartonBasicRate] = useState()
    const [changeValueCartonRate, setChangeValueCartonRate] = useState(0)
    const [changeValueStickRate, setChangeValueStickRate] = useState(0)
    const [changeValueKGRate, setChangeValueKGRate] = useState(0)
    // const [changeValueModuloRate,setChangeValueModuloRate] = useState(0)

    const handleValueCartonBasicRate = (event: any) => {
        setChangeValueCartonBasicRate(event.target.value);
    };


    const handleValueCartonRate = (event: any) => {
        setChangeValueCartonRate(event.target.value);
    };

    const handleValueStickRate = (event: any) => {
        setChangeValueStickRate(event.target.value);
    };

    const handleValueKGRate = (event: any) => {
        setChangeValueKGRate(event.target.value);
    };

    // const handleValueModuloRate = (event:any) => {
    //     setChangeValueModuloRate(event.target.value);
    // };


    const [newValueCartonBasicRate, setNewValueCartonBasicRate] = useState(0)
    const [newValueCartonRate, setNewValueCartonRate] = useState(0)
    const [newValueStickRate, setNewValueStickRate] = useState(0)
    const [newValueKGRate, setNewValueKGRate] = useState(0)

    // const [changeValueCartonRate,setChangeValueCartonRate] = useState(0)
    // const [changeValueStickRate,setChangeValueStickRate] = useState(0)
    // const [changeValueKGRate,setChangeValueKGRate] = useState(0)
    // const [changeValueModuloRate,setChangeValueModuloRate] = useState(0)


    useEffect(() => {


        switch (OperationCartonBasicRate) {
            case '?':
                setNewValueCartonBasicRate(oldValueCartonBasicRate)
                setValue("change_carton_basic_rate", 0)
                break;
            case '+':
                setNewValueCartonBasicRate(Number(oldValueCartonBasicRate) + Number(changeValueCartonBasicRate))
                break;
            case '-':
                setNewValueCartonBasicRate(Number(oldValueCartonBasicRate) - Number(changeValueCartonBasicRate))
                break;
            case '*':
                setNewValueCartonBasicRate(Number(oldValueCartonBasicRate) * Number(changeValueCartonBasicRate))
                break;
            case '/':
                setNewValueCartonBasicRate(Number(oldValueCartonBasicRate) / Number(changeValueCartonBasicRate))
                break;

            default:
            // calculatedResult = 'Invalid operation';
        }


        if (changeValueCartonBasicRate && oldValueCartonBasicRate) {
            setRateValue((Number(changeValueCartonBasicRate) / Number(oldValueCartonBasicRate)) * 100)
            // setValue("change_rate",(Number(changeValueCartonBasicRate) / Number(oldValueCartonBasicRate)) * 100)
        }
        setValue("new_carton_basic_rate", newValueCartonBasicRate)


    }, [OperationCartonBasicRate, oldValueCartonBasicRate, changeValueCartonBasicRate, newValueCartonBasicRate, rateValue])


    useEffect(() => {


        switch (OperationCartonRate) {
            case '?':
                setNewValueCartonRate(oldValueCartonRate)
                setValue("change_carton_rate", 0)
                break;
            case '+':
                setNewValueCartonRate(Number(oldValueCartonRate) + Number(changeValueCartonRate))
                break;
            case '-':
                setNewValueCartonRate(Number(oldValueCartonRate) - Number(changeValueCartonRate))
                break;
            case '*':
                setNewValueCartonRate(Number(oldValueCartonRate) * Number(changeValueCartonRate))
                break;
            case '/':
                setNewValueCartonRate(Number(oldValueCartonRate) / Number(changeValueCartonRate))
                break;

            default:
            // calculatedResult = 'Invalid operation';
        }

        setValue("new_carton_rate", newValueCartonRate)



    }, [OperationCartonRate, oldValueCartonRate, changeValueCartonRate, newValueCartonRate])



    useEffect(() => {


        switch (OperationStickRate) {
            case '?':
                setNewValueStickRate(oldValueStickRate)
                setValue("change_stick_rate", 0)
                break;
            case '+':
                setNewValueStickRate(Number(oldValueStickRate) + Number(changeValueStickRate))
                break;
            case '-':
                setNewValueStickRate(Number(oldValueStickRate) - Number(changeValueStickRate))
                break;
            case '*':
                setNewValueStickRate(Number(oldValueStickRate) * Number(changeValueStickRate))
                break;
            case '/':
                setNewValueStickRate(Number(oldValueStickRate) / Number(changeValueStickRate))
                break;

            default:
            // calculatedResult = 'Invalid operation';
        }

        setValue("new_stick_rate", newValueStickRate)

    }, [OperationStickRate, oldValueStickRate, changeValueStickRate, newValueStickRate])



    useEffect(() => {


        switch (OperationKGRate) {
            case '?':
                setNewValueKGRate(oldValueKGRate)
                setValue("change_one_kg_rate", 0)
                break;
            case '+':
                setNewValueKGRate(Number(oldValueKGRate) + Number(changeValueKGRate))
                break;
            case '-':
                setNewValueKGRate(Number(oldValueKGRate) - Number(changeValueKGRate))
                break;
            case '*':
                setNewValueKGRate(Number(oldValueKGRate) * Number(changeValueKGRate))
                break;
            case '/':
                setNewValueKGRate(Number(oldValueKGRate) / Number(changeValueKGRate))
                break;

            default:
            // calculatedResult = 'Invalid operation';
        }

        setValue("new_one_kg_rate", newValueKGRate)

    }, [OperationKGRate, oldValueKGRate, changeValueKGRate, newValueKGRate])



    // const[stickNOS,setStickNOS]:any = useState("")
    // const[cartonStick,setCartonStick]:any = useState("")
    // const[productTotalQty,setProductTotalQty]:any = useState("")

    // useEffect(()=>{
    //         setProductTotalQty(Number(stickNOS) * Number(cartonStick))
    //         // setValue("product_total_qty",productTotalQty)
    // },[stickNOS,cartonStick,productTotalQty])

    const [priceData, setPriceData]: any = useState()

    const onSubmit = (data: any) => {

        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
        }
        if (priceData) {

            delete priceData.isBasicRateEditable
            delete priceData.inputBasicRateValue
            delete priceData.isRateEditable
            delete priceData.inputRateValue
            delete priceData.isStickRateEditable
            delete priceData.inputStickRateValue
            delete priceData.isOneKgEditable
            delete priceData.inputOneKgValue





            const filteredPriceData = priceData.map((item: any) => {
                const filteredItem = {
                    old_carton_basic_rate: item.old_new_carton_basic_rate,
                    old_carton_rate: item.old_new_carton_rate,
                    old_stick_rate: item.old_new_stick_rate,
                    old_one_kg_rate: item.old_new_one_kg_rate,
                    old_percentage_rate: item.old_percentage_rate,

                    product_rate_change_date: item.product_rate_change_date,



                    product_code: item?.product_code?.id,
                    id: item?.id,
                    change_carton_basic_rate: item.change_carton_basic_rate,
                    change_carton_rate: item.change_carton_rate,
                    change_stick_rate: item.change_stick_rate,
                    change_one_kg_rate: item.change_one_kg_rate,
                    change_percentage_rate: item.change_percentage_rate,


                    new_carton_basic_rate: item.new_carton_basic_rate,
                    new_carton_rate: item.new_carton_rate,
                    new_stick_rate: item.new_stick_rate,
                    new_one_kg_rate: item.new_one_kg_rate,
                    new_percentage_rate: item.new_percentage_rate,
                };
                return filteredItem;
            });

            formData.append("pricelist_products", JSON.stringify(filteredPriceData));
        }

        let finalData = {
            apiname: apiname,
            id: id,
            data: formData
        }

        mutate(finalData);
        setValuesofform(finalData)
    }

    const [optionsProductType, setOptionsProductType] = useState([]);
    const [selectedProductType, setSelectedProductType]: any = useState("");

    const { data: productTypeData, refetch: productTypeRefetch } = useFetchMaterialTypeDropDown();

    useEffect(() => {
        setOptionsProductType(productTypeData);
    }, [productTypeData]);

    const [changeDate, setChangeDate]: any = useState(null)
    const dateFormatList = ['DD-MM-YYYY'];

    const [currentDate, setCurrentDate] = useState('')
    const [currentOldDate, setCurrentOldDate] = useState('')
    const handleChangeDate = (e: any, date: any) => {
        setChangeDate(dayjs(e, 'DD-MM-YYYY'))
        setValue("rate_change_date", date)
        setCurrentDate(date)
    }

    let fromDateValue = data?.rate_change_date

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")
    const [expirationDate, setExpirationDate] = useState()
    const [valuesofform, setValuesofform] = useState<any>()
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [openEditModel, setOpenEditModel] = useState(false)


    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess && Updated_response ||
            yesupdated_response ||
            noupdated_response) {

            if (Updated_response) {
                setMainData(Updated_response?.data)
                setTableData(Updated_response?.data?.price_list_products)
                setDataOn(Updated_response?.data)
                setRenderTable(renderTable + 1)
            }

            if (yesupdated_response) {
                setMainData(yesupdated_response?.data)
                setTableData(yesupdated_response?.data?.price_list_products)
                setDataOn(Updated_response?.data)
                setRenderTable(renderTable + 1)
            }

            if (noupdated_response) {
                setMainData(noupdated_response?.data)
                setTableData(noupdated_response?.data?.price_list_products)
                setDataOn(Updated_response?.data)
                setRenderTable(renderTable + 1)
            }
        }

    }, [data, isSuccess, yessuccess, yesupdated_response, noupdated_response,
        nosuccess, Updated_response])

    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setValuesofform(null)
            setShow(true)
        }
    }, [data, isSuccess, yessuccess,
        nosuccess, Updated_response])

    useEffect(() => {
        if (isError) {
            if (error.response.status === 405) {
                setConfirmmodal(true)
            }
        }
        else if (isError || yeserror || noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isError, yeserror, noerror])
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 5000)

    }, [show]);

    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }



    const optionsPriceListType = [
        {
            value: "All",
            label: "All"
        },
        {
            value: "Grade",
            label: "Grade"
        },
        {
            value: "Glass Name",
            label: "Glass Name"
        },
        {
            value: "Type",
            label: "Type"
        },
        {
            value: "Brand",
            label: "Brand"
        },
        {
            value: "Diameter",
            label: "Diameter"
        },
        {
            value: "Color",
            label: "Color"
        },
        {
            value: "ML.",
            label: "ML."
        }
    ]

    const [valuePriceListType, setValuePriceListType]: any = useState("")

    const [selectedPriceListType, setSelectedPriceListType]: any = useState("")



    const handleChangePriceListType = (e: any) => {
        setValuePriceListType(e)
        setSelectedPriceListType(e?.label)
        setValue("price_list_type", e?.label)
    }

    const [filterOptions, setFilterOptions]: any = useState([])
    const [selectedFilter, setSelectedFilter]: any = useState("")
    const [productBrand, setProductBrand]: any = useState()
    const [productColor, setProductColor]: any = useState()

    const handleChangeFilter = async (e: any) => {
        setSelectedFilter(e)
        if (selectedPriceListType?.label === "Brand" || valuePriceListType?.label === "Brand") {
            let brandLabel = e?.label
            await setProductBrand(brandLabel)

        }
        if (selectedPriceListType?.label === "Color" || valuePriceListType?.label === "Color") {
            let colorLabel = e?.label
            await setProductColor(colorLabel)

        }
    }

    useEffect(() => {
        if (selectedPriceListType?.label === "All" || valuePriceListType?.label === "All") {
            setSelectedFilter(null)
            product_typeMutate({ product_type: product_type })
        }
    }, [selectedPriceListType, valuePriceListType, selectedFilter])

    const [valueProductType, setValueProductType] = useState("")
    const [product_type, setProduct_type]: any = useState()

    useEffect(() => {
        setOptionsProductType(productTypeData);
    }, [productTypeData]);

    const handleChangeProductType = async (e: any) => {
        setValueProductType(e)
        setValue("product_type", e.value)
        setProduct_type(e?.label)

    }

    let apinameForProduct_Type = `${process.env.REACT_APP_DISPOSABLE_PRODUCT_API}/search_filter`
    const { mutate: product_typeMutate, data: product_typeData } = useCreate(apinameForProduct_Type)

    useEffect(() => {
        if (product_type) {
            product_typeMutate({ product_type: product_type })
        }
    }, [product_type])

    useEffect(() => {

        if (productBrand) {
            product_typeMutate({ product_type: product_type, product_brand: productBrand })
        }
        if (productColor) {
            product_typeMutate({ product_type: product_type, product_color: productColor })
        }
    }, [productBrand, productColor, selectedFilter])

    useEffect(() => {
    }, [product_typeData, selectedPriceListType])

    const [tableData, setTableData]: any = useState([])

    const optionsPriceListGrade = [
        {
            value: 1,
            label: "A"
        },
        {
            value: 2,
            label: "B"
        },
        {
            value: 3,
            label: "C"
        },
        {
            value: 4,
            label: "D"
        },
        {
            value: 5,
            label: "E"
        },

    ]
    const [valuePriceListGrade, setValuePriceListGrade]: any = useState("")
    const [selectedPriceListGrade, setSelectedPriceListGrade]: any = useState("")


    const handlePriceListGrade = (e: any) => {
        setValuePriceListGrade(e)
        setValue("grade", e?.label)
    }

    const [renderTable, setRenderTable] = useState(0)
    const [priceListAction, setPriceListAction] = useState()
    const handlePriceAction = (e: any) => {
        setPriceListAction(e.target.value)
        setRenderTable(renderTable + 1)
    }

    useEffect(() => {
        if (dataOn) {
            reset(dataOn)
            setValue("product_type", dataOn?.product_type?.id)
            setSelectedProductType(dataOn?.product_type?.id)
            setValue("price_list_type", dataOn?.price_list_type)
            setSelectedPriceListType(dataOn?.price_list_type)
            setMainData(dataOn)
            setTableData(dataOn?.price_list_products)
            setValue("grade", dataOn?.grade)
            setSelectedPriceListGrade(dataOn?.grade)
            setValue("price", dataOn?.price)
            setPriceListAction(dataOn?.price)
            setRateType(dataOn?.rate)
            setValue('rate', dataOn?.rate)
            setNewValueCartonBasicRate(dataOn?.new_carton_basic_rate)
            setNewValueCartonRate(dataOn?.new_carton_rate)
            setNewValueStickRate(dataOn?.new_stick_rate)
            setNewValueKGRate(dataOn?.new_one_kg_rate)
            setCurrentDate(dataOn?.rate_change_date)
            setCurrentOldDate(dataOn?.old_rate_change_date)
        }
    }, [dataOn, tableData])

    const [rateType, setRateType] = useState('')

    const handleChangeRateType = (e: any) => {
        setValue('rate', e.target.value)
        setRateType(e.target.value)
        setRenderTable(renderTable + 1)
    }
    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


    return (
        <div>

            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Masters</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">{pageName}</a></li>
                                    <li className="breadcrumb-item active">Edit {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body  py-0"
                                    style={{ maxHeight: "80vh", overflow: "auto" }}
                                >                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message[0]} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-sm-3 mt-4">
                                                                <label >Product Type<span style={{ color: 'red' }}>*</span></label>
                                                                <Select
                                                                    isDisabled
                                                                    {...register("product_type", {
                                                                        required: {
                                                                            value: true,
                                                                            message: "Field is required",
                                                                        },
                                                                    })}
                                                                    components={customIndicator}
                                                                    onChange={handleChangeProductType}
                                                                    options={optionsProductType}
                                                                    value={valueProductType == "" ? optionsProductType?.filter((e: any) => e.value === selectedProductType) : valueProductType}
                                                                />
                                                                <span className='text-red w-100'>{errors?.product_type?.message}</span>
                                                            </div>
                                                            <div className="col-sm-3 mt-4">
                                                                <label >Price-List Type<span style={{ color: 'red' }}>*</span></label>
                                                                <Select
                                                                    isDisabled
                                                                    {...register("price_list_type", {
                                                                        required: {
                                                                            value: true,
                                                                            message: "Field is required",
                                                                        },
                                                                    })}
                                                                    components={customIndicator}
                                                                    onChange={handleChangePriceListType}
                                                                    options={optionsPriceListType}
                                                                    value={valuePriceListType == "" ? optionsPriceListType?.filter((e: any) => e?.label === selectedPriceListType) : valuePriceListType}
                                                                />
                                                                <span className='text-red w-100'>{errors?.price_list_type?.message}</span>
                                                            </div>
                                                            {filterOptions &&
                                                                <>
                                                                    <div className="col-sm-3 mt-4">
                                                                        <label >{selectedPriceListType ? selectedPriceListType : valuePriceListType?.label}<span style={{ color: 'red' }}>*</span></label>

                                                                        <Select
                                                                            isDisabled
                                                                            placeholder={valuePriceListType?.label === "All" || selectedPriceListType === "All" ? "Choose another option for filter" : valuePriceListType?.label}
                                                                            onChange={handleChangeFilter}
                                                                            options={filterOptions}
                                                                            value={selectedFilter}
                                                                            components={customIndicator}
                                                                        />
                                                                    </div>
                                                                </>
                                                            }
                                                            <div className="col-sm-3 mt-4">
                                                                <label >Price Action<span className='text-red'>*</span></label>
                                                                <select className="form-select"
                                                                    {...register("price", {
                                                                        required: { value: true, message: "Action is required" }, maxLength: 30,
                                                                    })}
                                                                    onChange={handlePriceAction}
                                                                >
                                                                    <option value="" selected >Select Any</option>
                                                                    <option value="Up Price" >Up</option>
                                                                    <option value="Down Price" >Down</option>
                                                                </select>
                                                                <span className='text-red'>{errors?.price?.message}</span>
                                                            </div>
                                                            <div className="col-sm-3 mt-4">
                                                                <label >Rate Type With GST<span className='text-red'>*</span></label>
                                                                <select className="form-select"
                                                                    {...register("rate_type", {
                                                                        required: { value: true, message: "Rate is required" }, maxLength: 30,
                                                                    })}
                                                                >
                                                                    <option value="" selected >Select Any</option>
                                                                    <option value="With GST Rate" >With GST Rate</option>
                                                                    <option value="Basic Rate" >Basic Rate</option>
                                                                </select>
                                                                <span className='text-red'>{errors?.rate_type?.message}</span>
                                                            </div>
                                                            <div className="col-sm-3 mt-4">
                                                                <label >Rate Type<span className='text-red'>*</span></label>
                                                                <select className="form-select"
                                                                    {...register("rate", {
                                                                        onChange: handleChangeRateType,
                                                                        required: { value: true, message: "Rate is required" }, maxLength: 30,

                                                                    })}
                                                                >
                                                                    <option value="" selected >Select Any</option>
                                                                    <option value="Packet Rate" >Packet Rate</option>
                                                                    <option value="1 Kg. Rate" >1 Kg. Rate</option>
                                                                    <option value="% Rate" >% Rate</option>
                                                                    <option value="Carton Rate" >Carton Rate</option>
                                                                </select>
                                                                <span className='text-red'>{errors?.rate?.message}</span>
                                                            </div>
                                                            <div className="col-sm-3 mt-4">
                                                                <label >Price-List Name<span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group" >
                                                                    <input type="text"
                                                                        className="form-control"
                                                                        placeholder="Price-List Name"
                                                                        {...register("name", { onChange: handleValue, required: { value: true, message: "Field is required" } })}
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.name?.message}</span>
                                                            </div>


                                                            <div className="col-sm-3 mt-4">
                                                                <label >Price-List Grade<span style={{ color: 'red' }}>*</span></label>

                                                                <Select
                                                                    {...register("grade", {
                                                                        required: {
                                                                            value: true,
                                                                            message: "Field is required",
                                                                        },
                                                                    })}
                                                                    onChange={handlePriceListGrade}
                                                                    options={optionsPriceListGrade}
                                                                    // value={valuePriceListGrade}
                                                                    value={valuePriceListGrade == "" ? optionsPriceListGrade?.filter((e: any) => e?.label === selectedPriceListGrade) : valuePriceListGrade}
                                                                    components={customIndicator}

                                                                />
                                                                <span className='text-red w-100'>{errors?.grade?.message}</span>

                                                            </div>




                                                            <div className="col-sm-3 mt-4">
                                                                <label >Price-List Rate Change Date</label>
                                                                <div className="input-group" >

                                                                    <DatePicker
                                                                        className="form-control"
                                                                        format={dateFormatList}
                                                                        // value={changeDate}
                                                                        value={changeDate == undefined ? dayjs(fromDateValue, "DD-MM-YYYY") : changeDate}
                                                                        // disabledDate={disabledDate}

                                                                        {...register("rate_change_date", { required: { value: true, message: "Date is required" } })}

                                                                        onChange={handleChangeDate}
                                                                    />


                                                                </div>
                                                                <span className='text-red'>{errors?.rate_change_date?.message}</span>
                                                            </div>














                                                            {/* <Table
                  filterFields={filter}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={Columns_PriceList}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders}
                  permissionClass={permissionClass}
                  isMore={false}
                  isFooter={false}
                  isAddButton={false}
                  priceListData={tableData}
/> */}

                                                            {(tableData?.length > 0 ?
                                                                <Price_List_Table key={renderTable} data={tableData} currentOldDate={currentOldDate} mainEditData={data} currentDate={currentDate} priceListAction={priceListAction} rateType={rateType} setPriceData={setPriceData} editData={tableData} mainData={mainData} />
                                                                : ""
                                                            )}






                                                            {/* <div className="zui-wrapper" >
    <div className="zui-scroller">
        <table className="zui-table">
            <thead>
                <tr>
                    
                    <th className="zui-sticky-col">Sr.<br/>No</th>
                    <th className="zui-sticky-col2">Old Carton<br/>Basic Rate</th>
                    <th className="zui-sticky-col3">Item <br/> Description</th> 
                    <th>Godown <br/> Stock</th>
                    <th>Balance <br/>Stock</th>
                    <th>Lee10 <br/>Price</th>
                    <th>UOM</th>
                    <th>Order <br/>Qty</th>
                    <th>Discount<br/> After Tax</th>
                    <th>Discount %</th>
                    <th>1 KG<br/> rate</th>
                    <th>After Discount<br/> Box</th>
                    <th>Brand</th>
                    <th>Nos/Strip</th>
                    <th>Box/Strip</th>
                    <th>Cup <br/>Weight</th>
                    <th>Box <br/>Weight</th>
                    <th>Basic <br/>Discount</th>
                    <th>Total Basic<br/> Discount</th>
                    <th>Basic <br/>Rate per</th>
                    <th>Total <br/>Basic</th>
                    <th>GST <br/>Rate</th>
                    <th>GST<br/> Amount</th>
                    <th>Net <br/>Amount</th>
                    <th > Delete</th>
                    <th >Approve</th>
                    <th >Special <br/>Requirement</th>
                </tr>
            </thead>
            <tbody>
                <tr>
         <td className="zui-sticky-col">
         <input
         style={{border:"none",width:"50px",backgroundColor:"rgb(204, 204, 204)"}}
         
             type="text"
             placeholder="ID"
             disabled
            value={"1"}
           />
          
         </td>
         <td className="zui-sticky-col2">
         <input
         style={{border:"none",width:"100px",backgroundColor:"rgb(204, 204, 204)"}}
        
             type="text"
             value="123456788010"
             disabled
            
           />
           
         </td>
         <td className="zui-sticky-col3">
         <input
         style={{border:"none",width:"100px",backgroundColor:"rgb(204, 204, 204)"}}
        
             type="text"
             value={"Glass"}
             disabled
            
           />
           
         </td>
         <td>
         <input
         style={{border:"none"}}
         
             type="text"
             value={"100"}
             disabled
            
           />
           
         </td>
         <td>
           <input
           style={{border:"none"}}
           
             type="text"
             disabled
             value={"50"}
           />
           
         </td>
         <td>
         <input
         style={{border:"none"}}
        value={"2000.00"}
             type="text"
             
             disabled
           
           />
           
         </td>
         <td>
           <input
           style={{border:"none"}}
           value={"Box"}
             type="text"
             disabled
            
           />
           
         </td>
         <td>
         <input
         style={{border:"none"}}
        value={"10"}
             type="text"
             
             disabled
             />
         </td>
         <td>
         <input
         style={{border:"none"}}
        value={"100"}
             type="text"
             
             disabled
             />
         </td>
         <td>
         <input
         style={{border:"none"}}
         value={"5"}
             type="text"
             
             disabled
            
           />
         </td>
         <td>
         <input
         style={{border:"none"}}
         value={"126"}
             type="text"
             
             disabled
             />
         </td>
         <td>
         <input
         style={{border:"none"}}
         value={"1900"}
             type="text"
             
             disabled
            
           />
         </td>
         <td>
         <input
         style={{border:"none"}}
         value={"Lion"}
             type="text"
             
             disabled
           
           />
         </td>
         <td>
         <input
         style={{border:"none"}}
         value={"50"}
             type="text"
             
             disabled
           
           />
         </td>
         <td>
         <input
         style={{border:"none"}}
         value={"120"}
             type="text"
             
             disabled
            
           />
         </td>
         <td>
         <input
         style={{border:"none"}}
         value={"2.50"}
             type="text"
             
             disabled
            
           />
         </td>
         <td>
         <input
         style={{border:"none"}}
         value={"15.00"}
             type="text"
             
             disabled
            
           />
         </td>
         <td>
         <input
         style={{border:"none"}}
         value={"84.75"}
             type="text"
             
             disabled
            
           />
         </td>
         <td>
         <input
         style={{border:"none"}}
         value={"339.00"}
             type="text"
             
             disabled
            
           />
         </td>
         <td>
         <input
         style={{border:"none"}}
         
             type="text"
             value={"1695.00"}
             disabled
           
           />
         </td>
         <td>
         <input
         style={{border:"none"}}
         
             type="text"
             value={"6441.00"}
             disabled
            
           />
         </td>
         <td>
         <input
         style={{border:"none"}}
         
             type="text"
             value={"18"}
             disabled
            
           />
         </td>
         <td>
         <input
         style={{border:"none"}}
         value={"1159.38"}
             type="text"
             disabled
            
           />
         </td>
         <td>
         <input
         style={{border:"none"}}
         value={"7600.38"}
             type="text"
             disabled
           
           />
         </td>
         <td>
         <button
         style={{border:"none"}}
                  type="button"
                  className="btn btn-danger"
                >
                  <AiOutlineClose/>
                </button>
         </td>
         <td >
         <button
         style={{border:"none"}}
                  type="button"
                  className="btn btn-danger"
                >
                  <AiOutlineCheck/>
                </button>
         </td>
         <td >
         <select >
            <option value={""} selected>Select Any</option>
            <option value={"yes"}>Yes</option>
            <option value={"no"}>No</option>
         </select>
         </td>
                </tr>





                <tr className="font-weight-bold" >
        <td colSpan={3} className="zui-sticky-col" style={{backgroundColor:"rgb(204, 204, 204)",color:"black"}}>Total</td>
        <td >800</td>
        <td >400</td>
        <td >400</td>
        <td ></td>
        <td >26</td>
        <td >80.00</td>
        <td >3.43</td>
        <td >144.55</td>
        <td ></td>
        <td ></td>
        <td ></td>
        <td ></td>
        <td ></td>
        <td >17.36</td>
        <td >67.80</td>
        <td >1678.05</td>
        <td ></td>
        <td >58460.55</td>
        <td ></td>
        <td >10522.90</td>
        <td >68983.45</td>
        <td ></td>
        <td ></td>
        <td >   <button 
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                
                                                >Save</button></td>
       </tr>





            </tbody>
        </table>
    </div>
</div> */}



                                                            {/* <div className="col-sm-3 mt-4">
                                                    <label >Product Code<span style={{ color: 'red' }}>*</span></label>

                                                    <Select
                                                        {...register("product_code", {
                                                            required: {
                                                                value: true,
                                                                message: "Field is required",
                                                            },
                                                        })}
                                                        onChange={handleChangeDisposable}
                                                        options={optionsDisposable}
                                                        value={valueDisposable}
                                               
                                                    />
                                                    <span className='text-red w-100'>{errors?.product_code?.message}</span>

                                                </div> */}





                                                            {/* <div className="col-sm-3 mt-4">
                                                    <label >Product Grade, Name & Type<span style={{ color: 'red' }}>*</span></label>

                                                    <Select
                                                        {...register("product_name_and_grade_and_type", {
                                                            required: {
                                                                value: true,
                                                                message: "Field is required",
                                                            },
                                                        })}
                                                        onChange={handleChangeProductType}
                                                        options={optionsProductType}
                                                        value={prductTypeValue}
                                                  
                                                    />
                                                    <span className='text-red w-100'>{errors?.product_name_and_grade_and_type?.message}</span>

                                                </div> */}

                                                            {/* <div className="col-sm-3 mt-4">
                                                    <label >Show On-Off<span className='text-red'>*</span></label>
                                                    <select className="form-select"
                                                        {...register("show_on_off", {
                                                            required: { value: true, message: "Field is required" }, maxLength: 30,

                                                        })}
                                                    >
                                                        <option value="" disabled selected style={{ fontSize: "20px", padding: "5px", background: "rgb(170, 170, 170)", color: "white" }}>Select</option>
                                                        <option value="on" style={{ fontSize: "20px", padding: "5px" }}>On</option>
                                                        <option value="off" style={{ fontSize: "20px", padding: "5px" }}>Off</option>
                                                    </select>

                                                    <span className='text-red'>{errors?.show_on_off?.message}</span>

                                                </div> */}


                                                        </div>
                                                    </div>
                                                </div>


                                                {/* <div className="row mt-2">
                                        <h4 className="mb-0 mt-2">Change Rate</h4>
                                    </div>

                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">


                                           

                                                <div className="col-sm-3 mt-4">
                                                    <label >Carton Basic Rate<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
            <select className="" style={{borderRadius:"5px",border:"2px solid black"}} value={OperationCartonBasicRate} onChange={handleOperationCartonBasicRate}>
            <option value="?" selected>?</option>
                    <option value="+">+</option>
                    <option value="-">-</option>
                    <option value="*">*</option>
                    <option value="/">/</option>
                    <option value="%">%</option>
                </select>
                <input
                    type="number"
                    {...register("change_carton_basic_rate", { required: { value: true, message: "Field is required" } })} 
                    disabled={OperationCartonBasicRate === "?"}
                    placeholder="Carton Basic Rate"
                    className="form-control"
                    onChange={handleValueCartonBasicRate}
                />
               
            </div>
            <span className='text-red'>{errors?.change_carton_basic_rate?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-4">
                                                    <label >Carton Rate<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
            <select className="" style={{borderRadius:"5px",border:"2px solid black"}} value={OperationCartonRate} onChange={handleOperationCartonRate}>
            <option value="?" selected>?</option>
                    <option value="+">+</option>
                    <option value="-">-</option>
                    <option value="*">*</option>
                    <option value="/">/</option>
                    <option value="%">%</option>
                </select>
                <input
                    type="number"
                    {...register("change_carton_rate", { required: { value: true, message: "Field is required" } })} 
                    disabled={OperationCartonRate === "?"}
                    placeholder="Carton Rate"
                    className="form-control"
                    onChange={handleValueCartonRate}
                />
               
            </div>
            <span className='text-red'>{errors?.change_carton_rate?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-4">
                                                    <label >Stick Rate<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
            <select className="" style={{borderRadius:"5px",border:"2px solid black"}} value={OperationStickRate} onChange={handleOperationStickRate}>
            <option value="?" selected>?</option>
                    <option value="+">+</option>
                    <option value="-">-</option>
                    <option value="*">*</option>
                    <option value="/">/</option>
                    <option value="%">%</option>
                </select>
                <input
                    type="number"
                    {...register("change_stick_rate", { required: { value: true, message: "Field is required" } })} 
                    disabled={OperationStickRate === "?"}
                    placeholder="Stick Rate"
                    className="form-control"
                    onChange={handleValueStickRate}
                />
               
            </div>
            <span className='text-red'>{errors?.change_stick_rate?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-4">
                                                    <label >1 KG Rate<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
            <select className="" style={{borderRadius:"5px",border:"2px solid black"}} value={OperationKGRate} onChange={handleOperationKGRate}>
            <option value="?" selected>?</option>
                    <option value="+">+</option>
                    <option value="-">-</option>
                    <option value="*">*</option>
                    <option value="/">/</option>
                    <option value="%">%</option>
                </select>
                <input
                    type="number"
                    {...register("change_one_kg_rate", { required: { value: true, message: "Field is required" } })} 
                    disabled={OperationKGRate === "?"}
                    placeholder="1 KG Rate"
                    className="form-control"
                    onChange={handleValueKGRate}
                />
               
            </div>
            <span className='text-red'>{errors?.change_one_kg_rate?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-4">
                                                    <label >% Rate<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
            <select className="" style={{borderRadius:"5px",border:"2px solid black"}} value={OperationModuloRate} onChange={handleOperationModuloRate}>
            <option value="?" selected>?</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                   
                </select> 
                <input
                    type="number"
                    {...register("change_rate", { required: { value: false, message: "Field is required" } })} 
                    disabled={OperationModuloRate === "no"}
                    placeholder="% Rate"
                    className="form-control"
                    value={changeValueModuloRate}
                    onChange={handleValueModuloRate}
                />
               
            </div>
            <span className='text-red'>{errors?.change_rate?.message}</span>
                                                </div>


                                            </div>
                                        </div>
                                    </div> */}



                                                {/* <div className="row mt-2">
                                        <h4 className="mb-0 mt-2">GST Rate</h4>
                                    </div>

                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                                <div className="col-sm-3 mt-4">
                                                    <label >GST Change Amount<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number"
                                                        placeholder="GST Change Amount"
                                                            className="form-control" 
                                                            {...register("gst_change_amount", { required: { value: true, message: "Field is required" } })} />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.gst_change_amount?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-4">
                                                    <label >GST Rate %<span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                            {...register("gst_rate", {
                                            required: {
                                                value: true,
                                                message: "Field is required",
                                            },
                                            })}
                                            onChange={handleChangeGST}
                                            options={optionsGST}
                                            value={valueGST}
                                       
                                        />
                                        <span className='text-red w-100'>{errors?.gst_rate?.message}</span>
                                                </div>

                                             
                                                <div className="col-sm-3 mt-4">
                                                    <label >GST Amount<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number"
                                                        placeholder="GST Amount"
                                                            className="form-control" 
                                                            {...register("gst_amount", { required: { value: true, message: "Field is required" } })} />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.gst_amount?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-4">
                                                    <label >Difference Rate<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number"
                                                        placeholder="Difference Rate"
                                                            className="form-control" 
                                                            {...register("difference_rate", { required: { value: true, message: "Field is required" } })} />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.difference_rate?.message}</span>
                                                </div>

                                               


                                            </div>
                                        </div>
                                    </div> */}




                                                {/* <div className="row mt-2">
                                        <h4 className="mb-0 mt-2">New Rate</h4>
                                    </div>

                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                            <div className="col-sm-3 mt-4">
                                                    <label >Carton Basic Rate<span style={{ color: 'red' }}>*</span></label>
                                                        <input type="number"
                                                        disabled
                                                        value={newValueCartonBasicRate}
                                                            className="form-control" 
                                                            {...register("new_carton_basic_rate", { required: { value: false, message: "Field is required" } })} />
                                                    <span className='text-red w-100'>{errors?.new_carton_basic_rate?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-4">
                                                    <label >Carton Rate<span style={{ color: 'red' }}>*</span></label>
                                                        <input type="number"
                                                        disabled
                                                        value={newValueCartonRate}
                                                            className="form-control" 
                                                            {...register("new_carton_rate", { required: { value: false, message: "Field is required" } })} />
                                                    <span className='text-red w-100'>{errors?.new_carton_rate?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-4">
                                                    <label >Stick Rate<span style={{ color: 'red' }}>*</span></label>
                                                        <input type="number"
                                                        disabled
                                                        value={newValueStickRate}
                                                            className="form-control" 
                                                            {...register("new_stick_rate", { required: { value: false, message: "Field is required" } })} />
                                                    <span className='text-red w-100'>{errors?.new_stick_rate?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-4">
                                                    <label >1 KG Rate<span style={{ color: 'red' }}>*</span></label>
                                                        <input type="number"
                                                        disabled
                                                        value={newValueKGRate}
                                                            className="form-control" 
                                                            {...register("new_one_kg_rate", { required: { value: false, message: "Field is required" } })} />
                                                    <span className='text-red w-100'>{errors?.new_one_kg_rate?.message}</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div> */}










































                                                {/* <div className="col-sm-3 mt-4">
                                                    <label >Company Starting Date</label>
                                                    <div className="input-group" >

                                                    <DatePicker
                                                            className="form-control"
                                                            format={dateFormatList}
                                                            value={requirementDate}
                                                            // disabledDate={disabledDate}
                                                        
                                                            {...register("date", { required: { value: true, message: "Date is required" } })}

                                                            onChange={handleRequirementDate}
                                                        />

                                                        
                                                    </div>
                                                    <span className='text-red'>{errors?.date?.message}</span>
                                                </div>

                                               
                                                <div className="col-sm-3 mt-4">
                                                    <label >Product Weight Price List<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number"
                                                        placeholder="Product Weight Price List"
                                                            className="form-control" 
                                                            {...register("product_weight_price_list", { required: { value: true, message: "Field is required" } })} />
                                                            <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_weight_price_list?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-4">
                                                    <label >Stick Nos<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number"
                                                        placeholder="Stick Nos"
                                                            className="form-control" 
                                                            {...register("stick_nos", { onChange:handleStickNos,required: { value: true, message: "Field is required" } })} />
                                                            <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.stick_nos?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-4">
                                                    <label >Carton Stick<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number"
                                                        placeholder="Carton Stick"
                                                            className="form-control" 
                                                            {...register("carton_stick", { onChange:handleCartonStick,required: {  value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.carton_stick?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-4">
                                                    <label >Product Total Qty<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number"
                                                        disabled
                                                        value={productTotalQty}
                                                        placeholder="Product Total Qty"
                                                            className="form-control" 
                                                            {...register("product_total_qty", { required: { value: false, message: "Field is required" } })} />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_total_qty?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-4">
                                                    <label >UOM<span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                            {...register("unit_of_measure", {
                                            required: {
                                                value: true,
                                                message: "Field is required",
                                            },
                                            })}
                                            onChange={handleChangeUOM}
                                            options={optionsUOM}
                                            value={forUOM}
                                     
                                        />
                                        <span className='text-red w-100'>{errors?.unit_of_measure?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-4">
                                                    <label >Carton Weight<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number"
                                                        placeholder="Carton Weight"
                                                            className="form-control" 
                                                            {...register("carton_weight", { required: { value: true, message: "Field is required" } })} />
                                                            <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.carton_weight?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-4">
                                                    <label >Price List Mould Size(MM)<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number"
                                                        placeholder="Price List Mould Size(MM)"
                                                            className="form-control" 
                                                            {...register("price_list_mould_size_mm", { required: { value: true, message: "Field is required" } })} />
                                                            <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.price_list_mould_size_mm?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-4">
                                                    <label >Production Mould Size(MM)<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number"
                                                        placeholder="Production Mould Size(MM)"
                                                            className="form-control" 
                                                            {...register("production_mould_size_mm", { required: { value: true, message: "Field is required" } })} />
                                                            <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.production_mould_size_mm?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-4">
                                                    <label >ML Price List<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number"
                                                        placeholder="ML Price List"
                                                            className="form-control" 
                                                            {...register("ml_price_list", { required: { value: true, message: "Field is required" } })} />
                                                            <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.ml_price_list?.message}</span>
                                                </div>  



                                                <div className="col-sm-3 mt-4">
                                                    <label >Product Color<span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                            {...register("product_color", {
                                            required: {
                                                value: true,
                                                message: "Field is required",
                                            },
                                            })}
                                            onChange={handleChangeColor}
                                            options={optionsColor}
                                            value={valueColor}
                                            maxMenuHeight={120}
                                       
                                        />
                                        <span className='text-red w-100'>{errors?.product_color?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-4">
                                                    <label >Product Brand<span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                            {...register("product_brand", {
                                            required: {
                                                value: true,
                                                message: "Field is required",
                                            },
                                            })}
                                            onChange={handleChangeBrand}
                                            options={optionsBrand}
                                            value={valueBrand}
                                            maxMenuHeight={120}
                                    
                                        />
                                        <span className='text-red w-100'>{errors?.product_brand?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-4">
                                                    <label >Panni Color<span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                            {...register("panni_color", {
                                            required: {
                                                value: true,
                                                message: "Field is required",
                                            },
                                            })}
                                            isMulti
                                            onChange={handleChangePanniColor}
                                            options={optionsColor}
                                            value={valuePanniColor}
                                            maxMenuHeight={100}
                                    
                                        />
                                        <span className='text-red w-100'>{errors?.panni_color?.message}</span>
                                                </div> */}



                                                <div className="col-sm-3 mt-4">
                                                    <label >Status <span className='text-red'>*</span></label>
                                                    <select className="form-select"
                                                        {...register("status", {
                                                            required: { value: true, message: "Status is required" }, maxLength: 30,

                                                        })}
                                                    >
                                                        <option value="" selected >Select Any</option>
                                                        <option value="active" >Active</option>
                                                        <option value="inactive" >Inactive</option>
                                                    </select>

                                                    <span className='text-red'>{errors?.status?.message}</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                        onClick={handleSubmit(onSubmit)}
                                                    >Submit</button>
                                                    {/* <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button> */}

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPriceList")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
        </div>
    )
}

export default Edit_PriceList