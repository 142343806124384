import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { TiTickOutline } from "react-icons/ti";

import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Restricted from "../../Roles/Restricted";
import axios from "axios";
import FileDownload from 'js-file-download';
import { BiDotsVerticalRounded } from "react-icons/bi";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
const Download_Invoice = ({ row, apiname, deletemodalask, permissionClass, dos }: any) => {

  const handleDownloadInvoice = () => {
    return axios.get(
      `${process.env.REACT_APP_BASE_URL}salesUtils/invoice/?dispatchedInvoice_id=${row.original.id}`,

      {
        responseType: "blob",

        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": 'true',
          'Access-Control-Allow-Origin': 'true'
        },

      }
    ).then((response) => {
      FileDownload(response.data, 'invoice.pdf');
    });
  }


  return (
    <>
      <div
        className="vertical-align-center"
        style={{
          display: "flex !important",
          justifyContent: "space-evenly !important",

        }}
      >


        <OverlayTrigger

          trigger={"focus"}
          key={"bottom"}
          placement={"bottom-end"}
          overlay={
            <Popover >

              <Popover.Body >
                <div className="m-2">
                  <Button
                    onClick={handleDownloadInvoice}
                    className="btn btn-primary text-white mx-2"
                  >
                    Download Invoice
                  </Button>
                  {/*<button onClick={handleDispatchOrder} disabled={isDispatched} className="btn btn-primary text-white w-100">{isDispatched?"Dispatched":"Dispatch"}</button>*/}
                </div>
                <div className="m-2">
                </div>
              </Popover.Body>
            </Popover>
          }
        >



          <button
            // onClick={handleSubmitQuotation}
            className="btn btn-primary text-white"
          >

            <BiDotsVerticalRounded />
            {/* <RiDeleteBinLine />  */}
          </button>
        </OverlayTrigger>


        {/* <Restricted
          to={`${permissionClass?.front}.change_${permissionClass?.back}`}
          fallback={""}
        > */}

        {/* </Restricted> */}


      </div>
    </>
  );
};

export default Download_Invoice;

