import { useEffect, useState } from "react";
import "../../../components/Header/header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from 'axios'
import { useCreate } from "../../../hooks/Api";
import { RiDeleteBinLine } from "react-icons/ri";

const Verify_Products_List = () => {
  const location = useLocation();
  const dosDataStr = new URLSearchParams(location.search).get('dos');
  const [dosData, setDosData]: any = useState()
  useEffect(() => {
    if (dosDataStr) {
      const parsedData = JSON.parse(decodeURIComponent(dosDataStr));
      setDosData(parsedData);
      if (parsedData) {
        ExistedProductsMutate({ do_ids: parsedData });
      }
    }
  }, [dosDataStr]);


  useEffect(() => {
    if (dosData) {
      Dispach({ do_ids: dosData })
    }
  }, [dosData])

  let pageName = "Verify"
  const navigate = useNavigate()
  const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
    mode: "onChange"
  });
  const { errors } = formState;
  const apiname = process.env.REACT_APP_SALES_DISPATCH_ORDER_GET_DISPATCHE_ORDER_DATA_API?.toString()
  const { mutate: Dispach, isSuccess, isError, error, data: data_response }: any = useCreate(apiname)
  const apinameForExistedProducts = process.env.REACT_APP_SALES_DISPATCH_ORDER_GET_DISPATCHED_DATA_API?.toString();

  const { mutate: ExistedProductsMutate, data: ExistedProductsData, isError: ExistedProductsIsError, isLoading: ExistedProductsISLoading, isSuccess: ExistedProductsSuccess, error: ExistedProductsError }: any = useCreate(apinameForExistedProducts)
  const apinameForDispachedProducts = "sales/orderDispatched";
  const { mutate: dispachedProductsMutate, data: dispachedProductsData, isError: dispachedProductsIsError, isLoading: dispachedProductsISLoading, isSuccess: dispachedProductsSuccess, error: dispachedProductsError }: any = useCreate(apinameForDispachedProducts)
  const [exist, setExist] = useState(false)
  const [productsData, setProductsData]: any = useState([])
  let apiForInvoice = "sales/orderDispatched/submit_dispatch_order"
  const { mutate: invoiceMutate, data: invoiceData, isError: invoiceIsError, isLoading: invoiceIsLoading, isSuccess: invoiceIsSuccess, error: invoiceError }: any = useCreate(apiForInvoice)


  const handleInvoice = () => {
    invoiceMutate({ do_ids: dosData })

  }

  useEffect(() => {
    if (invoiceIsSuccess) {
      navigate("/listDispatch")
    }
  }, [invoiceIsSuccess])

  const handleConfirmInvoice = () => {
    axios.post(`${process.env.REACT_APP_BASE_URL}${apiForInvoice}/?force=true`, {
      do_ids: dosData
    })
      .then((response) => {
        navigate("/listDispatch")

      })
      .catch((error) => {
        console.error('There was an error!', error);
      });

  }

  const [isInvoice, setIsInvoice] = useState(false)

  useEffect(() => {
    if (dispachedProductsIsError) {
      if (dispachedProductsError?.response?.data?.message)

        setExist(true)
      setErrorMessage(dispachedProductsError?.response?.data?.message[0])
    }

    if (invoiceIsError) {
      if (invoiceError?.response?.data?.message) {
        setIsInvoice(true)
        setExist(true)
        setErrorMessage(invoiceError?.response?.data?.message[0])
      }
    }

  }, [dispachedProductsIsError, invoiceIsError])

  const [QrNumbers, setQrNumbers] = useState()
  const handleQRChange = (event: any) => {
    setQrNumbers(event?.target?.value);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [renderCounter, setRenderCounter] = useState(0);

  const [errorMessage, setErrorMessage] = useState('')
  useEffect(() => {
    if (isLoading) {
      setRenderCounter(prev => prev + 1);
    }
  }, [isLoading, renderCounter])

  useEffect(() => {
    if (ExistedProductsData) {
      let finalData = ExistedProductsData?.data?.map((e: any) => {
        if (e?.is_dispatched === false) {
          return e
        }
      })
      setProductsData(finalData);
    }
  }, [ExistedProductsData])

  const [valueDos, setValueDos]: any = useState()
  const [dos, setDos] = useState([])
  const handleChangeDispatch = (e: any) => {

    let dos = e?.map((e: any) => {
      return e?.value
    })
    setValue("product", dos)
    setValueDos(e)
    setDos(dos)
  }
  const [forceUpdate, setForceUpdate] = useState(0);

  const handleVerify = async (qrNumber: any) => {
    try {
      await dispachedProductsMutate({ do_ids: dosData, qr_number: QrNumbers });
      await new Promise(resolve => setTimeout(resolve, 500));
      await ExistedProductsMutate({ do_ids: dosData });
      let finalData = ExistedProductsData?.data?.map((e: any) => {
        if (e?.is_dispatched === false) {
          return e
        }
      })
      await setProductsData(finalData);
      await setForceUpdate(prev => prev + 1);
    } catch (error) {
      console.error("Error during verification:", error);
      setIsLoading(false);
    }
  };

  const [isDelete, setIsDelete] = useState(false)
  const handleDelete = async (e: any, field: any) => {
    e.preventDefault()
    await axios.delete(`${process.env.REACT_APP_BASE_URL}${apinameForDispachedProducts}/${field}/?force=true`).then((e: any) => {
    })
    await new Promise(resolve => setTimeout(resolve, 500));
    await ExistedProductsMutate({ do_ids: dosData });

    let finalData = ExistedProductsData?.data?.map((e: any) => {
      if (e?.is_dispatched === false) {
        return e
      }
    })

    await setProductsData(finalData);
    await setForceUpdate(prev => prev + 1);
  }

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                  <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
                  <li className="breadcrumb-item active">{pageName}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <form >
                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="row d-flex mb-2">
                    </div>
                    <div className="card" style={{ backgroundColor: "#013564", color: "white" }}>
                      <div className="card-body" style={{ padding: "0px" }}>
                        <div className="row">
                          <div className="col-sm-4" style={{ fontSize: "13px" }}>
                            <p className="card-text"><strong>Name:</strong> {data_response?.data[0]?.extra_response?.customer?.name} {data_response?.data[0]?.extra_response?.customer?.code}</p>
                            <p className="card-text"><strong>Phone:</strong> {data_response?.data[0]?.extra_response?.customer?.contact_detail_number}</p>
                          </div>
                          <div className="col-sm-4">
                            <p className="card-text"><strong>Address:</strong> {`${data_response?.data[0]?.extra_response?.delivery_address?.number},${data_response?.data[0]?.extra_response?.delivery_address?.building_name ? data_response?.data[0]?.extra_response?.delivery_address?.building_name : data_response?.data[0]?.extra_response?.delivery_address?.bunglow_name},${data_response?.data[0]?.extra_response?.delivery_address?.city_name ? data_response?.data[0]?.extra_response?.delivery_address?.city_name : data_response?.data[0]?.extra_response?.delivery_address?.village_name}`} </p>
                            <p className="card-text"><strong>Delivery Address:</strong> {`${data_response?.data[0]?.extra_response?.delivery_address?.number},${data_response?.data[0]?.extra_response?.delivery_address?.building_name ? data_response?.data[0]?.extra_response?.delivery_address?.building_name : data_response?.data[0]?.extra_response?.delivery_address?.bunglow_name},${data_response?.data[0]?.extra_response?.delivery_address?.city_name ? data_response?.data[0]?.extra_response?.delivery_address?.city_name : data_response?.data[0]?.extra_response?.delivery_address?.village_name}`}</p>
                          </div>
                          <div className="col-sm-4">
                            <p className="card-text"><strong>Sales Person Name:</strong> {data_response?.data[0]?.sales_order?.lead_generate?.sales_inquiry_code?.lead_generated_by_sales_executive_code_and_name?.name} {data_response?.data[0]?.sales_order?.lead_generate?.sales_inquiry_code?.lead_generated_by_sales_executive_code_and_name?.surname} {data_response?.data[0]?.sales_order?.lead_generate?.sales_inquiry_code?.code}</p>
                            <p className="card-text"><strong>D/O Number:</strong> {data_response?.data?.map((e: any) => e?.do_number)?.join(", ")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div className="col-md-2  mt-4">
                        <div className="mb-3">
                          <label htmlFor="inputField" className="form-label">QR Number</label>
                          <input
                            {...register("qr_number")}
                            className="form-control"
                            type="text"
                            placeholder="QR Number"
                            onChange={handleQRChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-2  mt-4" style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        paddingTop: "20px",
                      }}>
                        <div className="mb-3">
                          <button
                            type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                            onClick={handleVerify}
                          >Check</button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive" style={{ minHeight: "0px" }}>
                      <table className="table" key={forceUpdate} style={{ marginTop: "20px" }}>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Product Code</th>
                            <th>Order Qty</th>
                            <th>QR Number</th>
                            <th>Dispatched Stock</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productsData?.map((field: any, index: any) => {
                            return (
                              <tr key={index} >
                                <td>{field?.id}</td>
                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    disabled
                                    placeholder="Product Type"
                                    value={field?.sales_product?.product_code}
                                  />
                                </td>
                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    disabled
                                    value={field?.sales_product?.order_qty}
                                  />
                                </td>
                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    disabled
                                    value={field?.production?.qr_number}
                                  />
                                </td>
                                <td className="justify-content-center d-flex border-0">
                                  <button
                                    onClick={(e: any) => handleDelete(e, field?.id)}
                                    className="btn btn-primary text-white"
                                  >
                                    <RiDeleteBinLine />
                                  </button>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="row d-flex  ">
                      <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                        <div className="row d-flex ">
                          <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                            <button
                              type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                              style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                              onClick={handleInvoice}
                            >Generate Invoice</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </section>
      </div>
      {exist && (
        <div
          style={{ background: "#00000080" }}
          className="modal fade show d-block"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-danger" id="exampleModalLongTitle">Alert</h5>
              </div>
              <div className="modal-body">
                {errorMessage}
              </div>
              {isInvoice ? <>
                <div className="modal-footer">
                  <button
                    style={{ background: "#003665" }}
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setExist(false)}
                  >
                    No
                  </button>

                  <button
                    style={{ background: "#003665" }}
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleConfirmInvoice}
                  >
                    Yes
                  </button>
                </div>
              </> :
                <>
                  <div className="modal-footer">
                    <button
                      style={{ background: "#003665" }}
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setExist(false)}
                    >
                      Close
                    </button>
                  </div>
                </>}

            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Verify_Products_List