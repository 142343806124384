import { useEffect, useRef, useState } from "react";
import "../../../components/Header/header.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Select from 'react-select';
import { useCreate } from "../../../hooks/Api";
import useFocusOnEnter from "../../../components/focus";
import { useFetchCustomerDropDown, useFetchDispatchDropDown } from "../../../hooks/DropDown_Api";

const Add_Dispatch = () => {
    let pageName = "Dispatch"
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_WAREHOUSE_API?.toString()
    const { mutate: Warehouse, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)
    const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });
    const { errors } = formState;
    const [CustomerOptions, setCustomerOptions]: any = useState()
    const [byCustomer, setCustomer] = useState()
    const { data: CustomerData, refetch: CustomerRefetch } = useFetchCustomerDropDown(byCustomer)

    useEffect(() => {
        setCustomerOptions(CustomerData)
    }, [CustomerData])

    const handleChangeCustomer = (e: any) => {
        setValue("customer", e?.value)
    }

    const [DispatchOption, setDispatchOption] = useState()
    const { data: DispatchData, refetch: DispatchRefetch } = useFetchDispatchDropDown()

    useEffect(() => {
        setDispatchOption(DispatchData)
    }, [DispatchData])

    const handleChangeDispatch = (e: any) => {
        setValue("product", e?.value)
        setCustomer(e?.value)
    }

    const onSubmit = (branch: any) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(branch)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            if (Array.isArray(value) && key === "staff_code_and_name") {
                value.forEach((val, index) => formData.append(`staff_code_and_name`, val))
            }
        }
        Warehouse(formData)
    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listWarehouse', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error, isSuccess])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 5000)

    }, [show]);

    const handleReset = () => {
        reset()
    }
    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
                                    <li className="breadcrumb-item active">Add {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            <form ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" >
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Customer Name <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        {...register("customer", { required: { value: true, message: "Customer Name is required" } })}
                                                        placeholder={'Select Customer Name'}
                                                        onChange={handleChangeCustomer}
                                                        options={CustomerOptions}
                                                    />
                                                    <span className='text-red w-100'>{errors?.customer?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Dispatch Order <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        {...register("dispatch", { required: { value: true, message: "Dispatch Order is required" } })}
                                                        options={DispatchOption}
                                                        onChange={handleChangeDispatch}
                                                    />
                                                    <span className='text-red w-100'>{errors?.district?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)}
                                                    >Submit</button>
                                                    <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>
                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listWarehouse")}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Add_Dispatch